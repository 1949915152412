import React from 'react'
import { graphql, Link } from 'gatsby'
import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faFileArrowDown } from '@fortawesome/free-solid-svg-icons'
import Layout from '/src/components/layout'
import { SendEnquiryBlock } from '/src/components/common'

import './weld_zone_resources.scss'

export const Head = () => {
  return <title>Weld Zone Resources | Orrcon Steel</title>
}

const ResourceContent = ({ body }) => {
  return body.map(({ processed }) => {
    return (
      <div className="body mb-5" dangerouslySetInnerHTML={{
        __html: processed
      }}></div>
    )
  })
}

const ResourceDownload = ({ relationships }) => {
  const { field_resource_file } = relationships
  if (!field_resource_file || !field_resource_file.length) return <></>

  return <a className="download-button button primary" target="_blank" href={field_resource_file[0].uri.url}>
    Download
    <FontAwesomeIcon icon={faFileArrowDown} />
  </a>
}

const WeldZoneListItem = ({ title, body, relationships }) => {
  return (
    <Row>
      <Col sm={6}>
        <div className="title-container mb-3">
          <h4 className="title">{title}</h4>
        </div>
        <ResourceContent body={body} />
      </Col>
      <Col sm={6} className="d-flex justify-content-center align-items-center">
        <div>
          <ResourceDownload relationships={relationships} />
        </div>
      </Col>
    </Row>
  )
}

const WeldZoneList = ({ items }) => {
  return (
    <div className="weld-list">
      <Row>
        {items.map((item, index) => {
          return (
            <Col key={index} className="weld-list-item mb-5" md={12} lg={6}>
              <WeldZoneListItem {...item.node} />
            </Col>
          )
        })}
      </Row>
    </div>
  )
}

export default ({ data }) => {
  const resources = data.allNodeResources.edges

  return (
    <Layout>
      <Row className="mt-5 mb-5">
        <Col sm={12}>
          <div className="mb-5">
            <Link className="back-to-link" to='/resources'>
              <FontAwesomeIcon icon={faArrowLeft} />
              Back to resources
            </Link>
          </div>
          <WeldZoneList items={resources} />
        </Col>
      </Row>
      <Row className="mb-5">
        <Col xs={12}>
          <SendEnquiryBlock />
        </Col>
      </Row>
    </Layout>
  )
}

export const query = graphql`
  query {
    allNodeResources(
      filter: {relationships: {field_resource_category: {elemMatch: {name: {eq: "Weld Zone Resources"}}}}}
      sort: {fields: created, order: DESC}
    ) {
      edges {
        node {
          body {
            processed
          }
          path {
            alias
          }
          title
          relationships {
            node__product {
              title
              path {
                alias
              }
            }
            field_resource_file {
              uri {
                url
              }
            }
            field_resource_category {
              name
            }
          }
        }
      }
    }
  }
`

/*export const query = graphql`
  query {
    allNodeResources(
      filter: {relationships: {field_resource_category: {elemMatch: {name: {eq: "Weld Zone Resources"}}}}}
      sort: {fields: created, order: DESC}
    ) {
      edges {
        node {
          body {
            processed
          }
          path {
            alias
          }
          title
          relationships {
            node__product {
              title
              path {
                alias
              }
            }
            field_resource_file {
              publicUrl
            }
            field_resource_cover {
              gatsbyImage(
                fit: COVER
                formats: AUTO
                layout: FULL_WIDTH
                quality: 100
                placeholder: BLURRED
                width: 315
                height: 225
              )
            }
            field_resource_category {
              name
            }
          }
        }
      }
    }
  }
`*/
